import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const TermPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.term && (
      <Pill
        category="term"
        label={`${selectedFilters.term} month term`}
        value={selectedFilters.term}
        gaFilterName="contract_length"
      />
    )
  );
};

TermPills.propTypes = {
  selectedFilters: PropTypes.shape({
    term: PropTypes.string,
  }).isRequired,
};

export default TermPills;
