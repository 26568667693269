import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const LoadLength = ({ name, options = [], isDesktop, elasticSearchProps }) => {
  const optionsOrder = ['Up to 1000mm', '1000mm - 2400mm', '2400mm - 3000mm', '3000mm - 4000mm'].reverse();

  // .reverse() -> we want all of the options not in this list to be last, but the default with the below sort is that they're first.
  // so we reverse both lists so that the missing options are last instead.

  const sortedOptions = [...options]
    .sort((a, b) => {
      return optionsOrder.indexOf(a.value) - optionsOrder.indexOf(b.value);
    })
    .reverse();

  return (
    <FilterDrawerItem
      title="Load Length"
      name={name}
      options={sortedOptions}
      elasticSearchProps={elasticSearchProps}
      isDesktop={isDesktop}
    />
  );
};

LoadLength.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

LoadLength.defaultProps = {
  options: [],
  isDesktop: false,
};

export default LoadLength;
