import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const TransmissionPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.transmission && (
      <Pill category="transmission" value={selectedFilters.transmission} gaFilterName="gearbox" />
    )
  );
};

TransmissionPills.propTypes = {
  selectedFilters: PropTypes.shape({
    transmission: PropTypes.string,
  }).isRequired,
};

export default TransmissionPills;
