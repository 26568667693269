/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StepComponent = ({
  stepData,
  onAnswer,
  onNext,
  onPrevious,
  stepNumber,
  currentValue,
  steps,
  currentStep,
  errorMessage,
}) => {
  return (
    <div className="c-survey-modern">
      <div className="c-survey-modern__background">
        <picture className="responsive-background">
          <source media="(min-width: 992px)" srcSet={stepData.image} />
          <source media="(max-width: 991px)" srcSet={stepData.mobileImage} />
          <img src={stepData.image} alt="Background" className="responsive-background__image" />
        </picture>
      </div>
      <div className="c-survey-modern__content">
        <div className="c-survey-modern__inner-wrapper">
          <div className="c-survey-modern__progress-wrapper">
            <div className="c-progress-steps-modern">
              {steps.map((step, index) => (
                <>
                  <div
                    key={step.name}
                    className={classNames(
                      'c-progress-steps-modern__step',
                      index === currentStep && 'c-progress-steps-modern__step--active',
                    )}
                  >
                    <div className="c-progress-steps-modern__circle">
                      <i className="c-progress-steps-modern__icon">
                        <img src={step.icon} alt={step.title} />
                      </i>
                    </div>
                  </div>
                  {index !== steps.length - 1 && <div className="c-progress-steps-modern__line" />}
                </>
              ))}
            </div>
          </div>
          <span className="c-survey-modern__step">Step {stepNumber + 1} / 6</span>
          <h4 className="c-survey-modern__heading">{stepData.title}</h4>
          <p className="c-survey-modern__question">{stepData.question}</p>
          {stepData.description && <p className="c-survey-modern__description">{stepData.description}</p>}
          <div
            className={classNames({
              'c-survey-modern__options': true,
              'c-survey-modern__options--2-cols': stepData.columns === 2,
              'c-survey-modern__options--3-cols': stepData.columns === 3,
            })}
          >
            {stepData.options.map((option) => (
              <div className="c-radio-as-button" key={option.value}>
                <input
                  type="radio"
                  className="c-radio-as-button__input"
                  id={option.value}
                  value={option.value}
                  name={stepData.name}
                  checked={option.value === currentValue}
                  onChange={(e) => onAnswer(stepData.name, e.target.value, option.score)}
                />
                <label className="c-radio-as-button__label" htmlFor={option.value}>
                  <span>{option.label}</span>
                  {option.description && <small>{option.description}</small>}
                </label>
              </div>
            ))}
          </div>
          <div className="c-survey-modern__actions">
            <button className="c-survey-modern__prev-button" onClick={() => onPrevious()}>
              Previous step
            </button>
            <span>or</span>
            <button className="c-survey-modern__next-button" onClick={() => onNext()}>
              Next step
            </button>
            {errorMessage && <div className="c-survey-modern__error">{errorMessage}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

StepComponent.propTypes = {
  stepData: PropTypes.shape({
    image: PropTypes.string.isRequired,
    mobileImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    description: PropTypes.string,
    columns: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onAnswer: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  currentValue: PropTypes.string,
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

StepComponent.defaultProps = {
  currentValue: '',
  errorMessage: '',
};

export default StepComponent;
