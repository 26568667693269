import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import VanBodytypeFilter from './VanBodytypeFilter';
import CategoryFilters from './CategoryFilters';
import SpecialOffersFilters from './SpecialOffersFilters';
import FilterControls from './FilterControls';
import MobileFilterControls from './MobileFilterControls';
import ElasticSearchWrapper from './ElasticSearch';
import { useWindowSize } from '../../hooks';

const FiltersWrapper = ({
  actionName,
  isVans,
  controllerName,
  path,
  isPersonal,
  categoryType,
  labelMapping,
  isAdmin,
}) => {
  const [width] = useWindowSize();
  const specialOffersFiltersPortal = (
    <SpecialOffersFilters
      isPersonal={isPersonal}
      labelMapping={labelMapping}
      path={path}
      isDesktop={false}
      action={actionName}
      isVans={isVans}
      controller={controllerName}
    />
  );

  const categoryFiltersPortal = (
    <CategoryFilters
      path={path}
      isPersonal={isPersonal}
      isDesktop={false}
      categoryType={categoryType}
      isVans={isVans}
      controller={controllerName}
    />
  );

  const specialOffersPortalElement = document.getElementById('special-offers-filters-portal');

  const categoryPortalElement = document.getElementById('category-filters-portal');
  return (
    <ElasticSearchWrapper path={path} isPersonal={isPersonal} labelMapping={labelMapping}>
      <>
        {width <= 1200 &&
          isVans &&
          controllerName === 'specials' &&
          actionName !== 'vans_electric' &&
          actionName !== 'short_term' && <VanBodytypeFilter path={path} isDesktop={false} />}
        <div className="drv-filter-triggers">
          <div className="drv-filter-triggers__trigger drv-filter-triggers__trigger--filter">
            <button href="#" data-drawer=".g-drawer--filter">
              Filters
            </button>
          </div>
          <div className="drv-filter-triggers__trigger drv-filter-triggers__trigger--sort">
            <button href="#" data-drawer=".g-drawer--sort">
              Sort by
            </button>
          </div>
        </div>
        <MobileFilterControls
          labelMapping={labelMapping}
          isAdmin={isAdmin}
          path={path}
          controller={controllerName}
          isVans={isVans}
          className=""
        />
        {actionName !== 'vans_electric' && (
          <>
            {width > 1200 && isVans && controllerName === 'specials' && actionName !== 'short_term' && (
              <VanBodytypeFilter path={path} isDesktop />
            )}
            {controllerName === 'categories' && (
              <div className="drv-filter-desktop">
                <CategoryFilters
                  path={path}
                  isPersonal={isPersonal}
                  isDesktop
                  categoryType={categoryType}
                  isVans={isVans}
                  controller={controllerName}
                />
              </div>
            )}
            {controllerName === 'specials' && (
              <div className="drv-filter-desktop">
                <SpecialOffersFilters
                  isPersonal={isPersonal}
                  labelMapping={labelMapping}
                  path={path}
                  isDesktop
                  action={actionName}
                  isVans={isVans}
                  controller={controllerName}
                />
              </div>
            )}
          </>
        )}
        <div className="filter-controls">
          <FilterControls
            labelMapping={labelMapping}
            isAdmin={isAdmin}
            path={path}
            controller={controllerName}
            action={actionName}
            isVans={isVans}
            isOnlySortVisible={actionName === 'vans_electric'}
            fundersVisible={actionName === 'all'}
          />
        </div>
        {specialOffersPortalElement
          ? ReactDOM.createPortal(specialOffersFiltersPortal, specialOffersPortalElement)
          : null}
        {categoryPortalElement ? ReactDOM.createPortal(categoryFiltersPortal, categoryPortalElement) : null}
      </>
    </ElasticSearchWrapper>
  );
};

FiltersWrapper.propTypes = {
  actionName: PropTypes.string.isRequired,
  isVans: PropTypes.bool.isRequired,
  controllerName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isPersonal: PropTypes.bool.isRequired,
  categoryType: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  labelMapping: PropTypes.shape({}).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

FiltersWrapper.defaultProps = {
  categoryType: null,
};

export default FiltersWrapper;
