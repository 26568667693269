import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const BudgetPills = ({ selectedFilters, labelMapping }) => {
  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  return (
    !!selectedFilters.monthly_prices && (
      <Pill
        category="monthly_prices"
        label={getKeyByValue(labelMapping, selectedFilters.monthly_prices)}
        value={selectedFilters.monthly_prices}
        gaFilterName="budget"
      />
    )
  );
};

BudgetPills.propTypes = {
  selectedFilters: PropTypes.shape({
    monthly_prices: PropTypes.string,
  }).isRequired,
  labelMapping: PropTypes.shape({}).isRequired,
};

export default BudgetPills;
