import React from 'react';
import PropTypes from 'prop-types';

import FilterList from '../ui/FilterList';
import Make from './SpecialOffersFilters/Manufacturer';
import Model from './SpecialOffersFilters/Model';
import Transmission from './SpecialOffersFilters/Transmission';
import FuelType from './SpecialOffersFilters/FuelType';
import BodyType from './SpecialOffersFilters/BodyType';
import { useElasticSearch } from './ElasticSearch';

const BudgetFilters = ({ isDesktop }) => {
  const elasticSearch = useElasticSearch();

  return (
    <FilterList heading="Choose vehicle details" isDesktop={isDesktop}>
      <FuelType
        name="display_fueltype"
        options={elasticSearch.allFiltersFor('display_fueltype')}
        elasticSearchProps={elasticSearch.props}
        isDesktop={isDesktop}
      />
      <Make
        name="make[]"
        options={elasticSearch.allFiltersFor('make')}
        elasticSearchProps={elasticSearch.props}
        isDesktop={isDesktop}
      />
      <Model
        name="make_model_range[]"
        options={elasticSearch.availableFiltersFor('make_model_range')}
        elasticSearchProps={elasticSearch.props}
        isDesktop={isDesktop}
      />
      <Transmission
        name="transmission"
        options={elasticSearch.allFiltersFor('transmission')}
        elasticSearchProps={elasticSearch.props}
        isDesktop={isDesktop}
      />
      <BodyType
        name="bodytype_categories[]"
        options={elasticSearch.allFiltersFor('bodytype_categories')}
        elasticSearchProps={elasticSearch.props}
        isDesktop={isDesktop}
      />
    </FilterList>
  );
};

BudgetFilters.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};

export default BudgetFilters;
