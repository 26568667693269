import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const EstimatedDeliveryPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.estimated_delivery && (
      <Pill
        category="estimated_delivery"
        label={`${selectedFilters.estimated_delivery} delivery`}
        value={selectedFilters.estimated_delivery}
      />
    )
  );
};

EstimatedDeliveryPills.propTypes = {
  selectedFilters: PropTypes.shape({
    estimated_delivery: PropTypes.string,
  }).isRequired,
};

export default EstimatedDeliveryPills;
