import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const InStock = ({ name, options, elasticSearchProps }) => {
  const getLabel = (value) => (value === 'true' ? 'Yes' : 'No');

  const count = elasticSearchProps.selectedFilters[name]
    ? getLabel(elasticSearchProps.selectedFilters[name])
    : undefined;

  options = options.map((option) => ({ ...option, label: getLabel(option.value) }));

  return (
    <FilterDrawerItem
      title="In stock?"
      name={name}
      count={count}
      options={options}
      elasticSearchProps={elasticSearchProps}
    />
  );
};

InStock.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

InStock.defaultProps = {
  options: [],
};

export default InStock;
