import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const LoadLengthPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.load_length && (
      <Pill category="load_length" label={`${selectedFilters.load_length}`} value={selectedFilters.load_length} />
    )
  );
};

LoadLengthPills.propTypes = {
  selectedFilters: PropTypes.shape({
    load_length: PropTypes.string,
  }).isRequired,
};

export default LoadLengthPills;
