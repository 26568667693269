import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const MakePills = ({ selectedFilters }) => {
  return (
    !!selectedFilters['make[]'] &&
    selectedFilters['make[]'].map((make, makeIndex, makes) => (
      <React.Fragment key={make}>
        <Pill
          category="make[]"
          value={make}
          gaFilterName="manufacturer"
          isMake
          isLast={
            makeIndex + 1 === makes.length &&
            selectedFilters['make_model_range[]'] &&
            !selectedFilters['make_model_range[]'].filter((model) => model.startsWith(make)).length
          }
        />

        {selectedFilters['make_model_range[]'] &&
          selectedFilters['make_model_range[]']
            .filter((model) => model.startsWith(make))
            .map((model, modelIndex, models) => (
              <Pill
                key={model}
                category="make_model_range[]"
                gaFilterName="model"
                label={model.replace(`${make},`, '')}
                value={model}
                isLast={makeIndex + 1 === makes.length && modelIndex + 1 === models.length}
              />
            ))}
      </React.Fragment>
    ))
  );
};

MakePills.propTypes = {
  selectedFilters: PropTypes.shape({
    'make_model_range[]': PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default MakePills;
