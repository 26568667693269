/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from '../../store/store';
import { getQueryStringParameters, objectToQueryStringParameters } from '../../utils';

const ModelFiltersContext = createContext(null);

const PRICE_FILTERS = ['initial_payment', 'mileage', 'term'];

export const ModelFiltersProvider = ({ path, children, isVans, controller }) => {
  const [initialData, setInitialData] = useState(null);
  const [availableData, setAvailableData] = useState(null);
  const initialLoadCompleted = useRef(false);

  const defaultSelectedFilters = {
    ...getQueryStringParameters(),
  };

  const { filters: selectedFilters } = useSelector((state) => state.filterControls);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_IS_VANS', payload: isVans });
    dispatch({ type: 'SET_CONTROLLER', payload: controller });
  }, [dispatch, isVans, controller]);

  const setSelectedFilter = (event) => {
    const { name, value } = event.target;
    const newSelectedFilters = { ...selectedFilters };

    if (value) {
      newSelectedFilters[name] = value;
    } else {
      delete newSelectedFilters[name];
    }

    dispatch({ type: 'SET_FILTERS', payload: newSelectedFilters });
  };

  const removeSelectedFilters = (name) => {
    const newSelectedFilters = { ...selectedFilters };

    if (Array.isArray(name)) {
      name.forEach((nameItem) => delete newSelectedFilters[nameItem]);
    } else {
      delete newSelectedFilters[name];
    }

    dispatch({ type: 'SET_FILTERS', payload: newSelectedFilters });
  };

  const filterParams = objectToQueryStringParameters(selectedFilters);

  const initialiseReduxState = () => {
    dispatch({ type: 'INITIALISE_FILTERS', payload: defaultSelectedFilters });
  };

  const dispatchAndUpdateCountElements = (count) => {
    dispatch({
      type: 'TOTAL_COUNTS',
      payload: { count },
    });

    const countElements = document.querySelectorAll('#item-count-element');
    countElements.forEach((element) => {
      element.innerText = `- ${count} vehicles found`;
    });
  };

  // Fetch initial data on component mount or when URL parameters change
  useEffect(() => {
    const fetchInitialData = async () => {
      if (path && !initialLoadCompleted.current) {
        try {
          const url = `${path}.json`;
          const response = await axios.get(url);
          setInitialData(response.data);
          setAvailableData(response.data);

          initialiseReduxState();

          // Update count elements if there are no filters applied
          if (!filterParams && response.data?.deals?.count !== undefined) {
            dispatchAndUpdateCountElements(response.data.deals.count);
          }

          initialLoadCompleted.current = true;
        } catch (error) {
          console.error('Failed to fetch initial model data', error);
        }
      }
    };

    fetchInitialData();
  }, [path, filterParams]);

  // Fetch filtered data when filters change
  useEffect(() => {
    const fetchFilteredData = async () => {
      if (path && initialLoadCompleted.current) {
        try {
          const response = await axios.get(`${path}.json?${filterParams}`);
          setAvailableData(response.data);

          if (response.data?.deals?.count !== undefined) {
            dispatchAndUpdateCountElements(response.data.deals.count);
          }
        } catch (error) {
          console.error('Failed to fetch filtered model data', error);
        }
      }
    };

    const timer = setTimeout(() => {
      if (initialLoadCompleted.current) {
        fetchFilteredData();
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [filterParams]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const derivativesRows = document.querySelector('.g-derivatives__rows');
      const moreButton = document.querySelector('.g-derivatives__more');

      if (derivativesRows && moreButton) {
        const rowCount = derivativesRows.children.length;
        const totalCount = availableData?.deals?.count || 0;

        if (totalCount > rowCount) {
          moreButton.classList.add('g-derivatives__more--show');
        } else {
          moreButton.classList.remove('g-derivatives__more--show');
        }
      }
    });

    const derivativesRows = document.querySelector('.g-derivatives__rows');
    if (derivativesRows) {
      observer.observe(derivativesRows, { childList: true });
    }

    return () => observer.disconnect();
  }, [availableData?.deals?.count]);

  const allFiltersFor = (name) => {
    if (!initialData?.aggregations?.[name]) return [];

    const allOptions = initialData.aggregations[name];
    const availableOptions = availableData?.aggregations?.[name] || [];

    return allOptions.map((option) => {
      const availableOption = availableOptions.find((o) => o.key === option.key);
      return {
        value: option.key.toString(),
        label: option.key.toString(),
        enabled: PRICE_FILTERS.includes(name) ? true : !!availableOption,
        count: PRICE_FILTERS.includes(name) ? 0 : availableOption?.doc_count || 0,
        cheapest_motr: option.cheapest_motr?.value,
        selected: selectedFilters[name] === option.key.toString(),
      };
    });
  };

  const hasAnyOption = (name, value) => {
    if (!initialData?.aggregations?.[name]) return false;
    const option = initialData.aggregations[name].find((o) => o.key.toString() === value.toString());
    return !!option && option.doc_count > 0;
  };

  const availableFiltersFor = (name) => {
    if (!availableData?.aggregations?.[name]) return [];

    return availableData.aggregations[name].map((option) => ({
      value: option.key.toString(),
      count: option.doc_count,
    }));
  };

  // TODO: fix eslint error instead of disabling it
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    allFiltersFor,
    availableFiltersFor,
    hasAnyOption,
    props: {
      selectedFilters,
      setSelectedFilter,
      removeSelectedFilters,
    },
  };

  return <ModelFiltersContext.Provider value={contextValue}>{children}</ModelFiltersContext.Provider>;
};

export const useModelFilters = () => {
  const context = useContext(ModelFiltersContext);
  if (!context) {
    throw new Error('useModelFilters must be used within a ModelFiltersProvider');
  }
  return context;
};

ModelFiltersProvider.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node,
  isVans: PropTypes.bool,
  controller: PropTypes.string,
};

ModelFiltersProvider.defaultProps = {
  path: '',
  children: null,
  isVans: false,
  controller: '',
};

export default function ModelFiltersWrapper(props) {
  return (
    <Provider store={store}>
      <ModelFiltersProvider {...props} />
    </Provider>
  );
}
