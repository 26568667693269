import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ModelFiltersWrapper from './ModelFiltersContext';
import DesktopModelFilters from './DesktopModelFilters';
import ModelPills from './ModelPills';
import MobileModelFilters from './MobileModelFilters';

const ModelFilters = ({ path, controllerName, isVans }) => {
  const mobileFiltersPortal = <MobileModelFilters />;
  const mobileFiltersElement = document.getElementById('mobile-model-filters-portal');

  return (
    <ModelFiltersWrapper path={path} controller={controllerName} isVans={isVans}>
      <DesktopModelFilters />
      <ModelPills />
      {mobileFiltersElement ? ReactDOM.createPortal(mobileFiltersPortal, mobileFiltersElement) : null}
    </ModelFiltersWrapper>
  );
};

ModelFilters.propTypes = {
  path: PropTypes.string.isRequired,
  controllerName: PropTypes.string.isRequired,
  isVans: PropTypes.bool.isRequired,
};

export default ModelFilters;
