import React from 'react';
import PropTypes from 'prop-types';

const OptionList = ({ children }) => {
  return <ul className="g-drawer__list">{children}</ul>;
};

OptionList.propTypes = {
  children: PropTypes.node.isRequired,
};

OptionList.Item = ({
  id,
  name,
  value,
  label,
  checked,
  onChange,
  disabled,
  type,
  gaEventAction,
  gaFilterName,
  gaFilterOption,
}) => {
  return (
    <li className="g-drawer__item">
      <div className={type === 'radio' ? 'custom-radio' : 'custom-checkbox'}>
        <input
          className="custom-control-input"
          type={type}
          id={id}
          name={name}
          defaultChecked={checked}
          value={value}
          onChange={onChange}
          disabled={disabled}
          data-action="ga-filters#push"
          data-ga-filters-event-action={gaEventAction}
          data-ga-filters-filter-name={gaFilterName}
          data-ga-filters-filter-option={gaFilterOption}
        />

        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </li>
  );
};

OptionList.Item.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  gaEventAction: PropTypes.string,
  gaFilterName: PropTypes.string,
  gaFilterOption: PropTypes.string,
};

OptionList.Item.defaultProps = {
  name: '',
  type: 'radio',
  checked: false,
  onChange: () => {},
  disabled: false,
  gaEventAction: '',
  gaFilterName: '',
  gaFilterOption: '',
};

export default OptionList;
