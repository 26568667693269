import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const MileagePills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.mileage && (
      <Pill
        category="mileage"
        label={`${Math.floor(selectedFilters.mileage).toLocaleString('en-GB')} miles`}
        value={selectedFilters.mileage}
        gaFilterName="annual_mileage"
      />
    )
  );
};

MileagePills.propTypes = {
  selectedFilters: PropTypes.shape({
    mileage: PropTypes.string,
  }).isRequired,
};

export default MileagePills;
