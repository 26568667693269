import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import FilterList from '../ui/FilterList';

import ContractLength from './SpecialOffersFilters/ContractLength';
import AnnualMileage from './SpecialOffersFilters/AnnualMileage';
import InitialPayment from './SpecialOffersFilters/InitialPayment';
import { useElasticSearch } from './ElasticSearch';

const StockOffersFilters = ({ isDesktop }) => {
  const elasticSearch = useElasticSearch();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_THEME', payload: '' });
  }, []);

  return (
    <FilterList heading="Vehicle options" isDesktop={isDesktop}>
      <ContractLength
        name="term"
        options={elasticSearch.allFiltersFor('term')}
        elasticSearchProps={elasticSearch.props}
        isDesktop={isDesktop}
      />

      <AnnualMileage
        name="mileage"
        options={elasticSearch.allFiltersFor('mileage')}
        elasticSearchProps={elasticSearch.props}
        isDesktop={isDesktop}
      />

      <InitialPayment
        name="initial_payment"
        options={elasticSearch.allFiltersFor('initial_payment')}
        elasticSearchProps={elasticSearch.props}
        isDesktop={isDesktop}
      />
    </FilterList>
  );
};

StockOffersFilters.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};

export default StockOffersFilters;
