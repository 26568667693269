import React from 'react';

import FilterDrawerItem from '../ui/FilterDrawerItem';
import AnnualMileage from './SpecialOffersFilters/AnnualMileage';
import InitialPayment from './SpecialOffersFilters/InitialPayment';
import ContractLength from './SpecialOffersFilters/ContractLength';

import { useModelFilters } from './ModelFiltersContext';

const AllModelFilters = () => {
  const modelFilters = useModelFilters();

  return (
    <ul className="drv-ui drv-model-filters drv-model-filters--desktop pt-0">
      <FilterDrawerItem
        title="Trim level"
        name="trim"
        options={modelFilters.allFiltersFor('trim')}
        elasticSearchProps={modelFilters.props}
        isDesktop
        noWrap
      />

      <FilterDrawerItem
        title="Fuel type"
        name="display_fueltype"
        options={modelFilters.allFiltersFor('display_fueltype')}
        elasticSearchProps={modelFilters.props}
        isDesktop
      />

      <FilterDrawerItem
        title="Gearbox"
        name="transmission"
        options={modelFilters.allFiltersFor('transmission')}
        elasticSearchProps={modelFilters.props}
        isDesktop
      />

      <InitialPayment
        name="initial_payment"
        options={modelFilters.allFiltersFor('initial_payment')}
        elasticSearchProps={modelFilters.props}
        isDesktop
      />

      <AnnualMileage
        name="mileage"
        options={modelFilters.allFiltersFor('mileage')}
        elasticSearchProps={modelFilters.props}
        isDesktop
      />

      <ContractLength
        name="term"
        options={modelFilters.allFiltersFor('term')}
        elasticSearchProps={modelFilters.props}
        isDesktop
      />
    </ul>
  );
};

export default AllModelFilters;
