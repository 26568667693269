import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const InitialPaymentPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.initial_payment && (
      <Pill
        category="initial_payment"
        label={`${selectedFilters.initial_payment} Month initial payment`}
        value={selectedFilters.initial_payment}
        gaFilterName="initial_payment"
      />
    )
  );
};

InitialPaymentPills.propTypes = {
  selectedFilters: PropTypes.shape({
    initial_payment: PropTypes.string,
  }).isRequired,
};

export default InitialPaymentPills;
