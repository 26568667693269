import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';

import Pills from './SpecialOffersFilters/filterPills/Pills';
import InStockToggle from './InStockToggle';
import OVOToggle from './OVOToggle';
import { shouldShowFeature } from '../../splitTesting';
import { useElasticSearch } from './ElasticSearch';

const ClearAllButton = ({ onClick, theme }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <span
    className={classnames('drv-link', {
      'drv-link--gold': !theme,
      [`drv-link--${theme}`]: theme,
    })}
    data-action="click->ga-filters#push"
    data-ga-filters-event-action="cleared_all_filters"
    onClick={onClick}
  >
    Clear all
    <svg
      fill={theme === 'electric' ? '#00a3e0' : '#ac8c30'}
      height="20"
      viewBox="228.61 210.78 10.21 12.02"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m233.71817501636602,222.80117904990522 c-2.8198594488203526,0 -5.10526180267334,-2.39004047670465 -5.10526180267334,-5.343179495041285 s2.2854023538529873,-5.343179495041285 5.10526180267334,-5.343179495041285 l0,-1.3352021604064999 l2.9201778432428833,1.8040021347066384 l-2.9201778432428833,1.6168557087302022 l0,-1.2514356213533258 c-2.377137526869774,0 -4.311553131788969,2.022983649566763 -4.311553131788969,4.50895943336427 c0,2.4859757837975067 1.9344156049191952,4.50895943336427 4.311553131788969,4.50895943336427 s4.311553131788969,-2.0062992483332227 4.311553131788969,-4.50895943336427 l0.7937086708843714,0 c0,2.9614812189534057 -2.2854023538529873,5.343179495041285 -5.10526180267334,5.343179495041285 z" />
    </svg>
  </span>
);

ClearAllButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

ClearAllButton.defaultProps = {
  theme: '',
};

const MobileFilterControls = ({ labelMapping, path, isVans, controller }) => {
  const pillsRef = useRef();
  const { hasAnyOption } = useElasticSearch();

  const { filters, totalCounts, theme } = useSelector((state) => state.filterControls);
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(true);
  const [filterList, setFilterList] = useState([]);

  const showInStock = shouldShowFeature('stock') && controller === 'specials' && hasAnyOption('stock', 'true');
  const showOvo = !isVans && controller === 'specials' && hasAnyOption('promotion_name', 'ovo promotion');
  const showOnlyInStock = showInStock && !showOvo;
  const showOnlyOvo = showOvo && !showInStock;
  const showSingleToggle = showOnlyInStock || showOnlyOvo;

  const clearAll = (event) => {
    const form = event.target.closest('form');

    dispatch({ type: 'RESET_FILTERS' });

    setTimeout(() => {
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 1);
  };

  useEffect(() => {
    setFilterList(Object.keys(filters));
  }, [filters]);

  useEffect(() => {
    const filtersWithoutDefaults = filters;
    delete filtersWithoutDefaults.customer_type;
    delete filtersWithoutDefaults.sort_by;

    if (Object.keys(filtersWithoutDefaults).length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [filters, filterList.map((name) => `filters[${name}]`)]);

  return (
    <>
      {showInStock && showOvo && (
        <div className="drv-filter-toggles">
          <InStockToggle isDesktopVersion={false} />
          <OVOToggle isDesktopVersion={false} />
        </div>
      )}
      <div
        className={classnames('drv-filter-controls__mobile', {
          [`drv-filter-controls--${theme}`]: theme,
        })}
      >
        <div className="drv-filter-controls__mobile--primary">
          <div>
            <span className="drv-filter-controls__result-count">{totalCounts.count}</span> results
          </div>
          {!isEmpty && ((showInStock && showOvo) || controller !== 'specials') && (
            <ClearAllButton onClick={clearAll} theme={theme} />
          )}
          {showOnlyOvo && <OVOToggle path={path} isDesktopVersion={false} />}
          {showOnlyInStock && <InStockToggle path={path} isDesktopVersion={false} />}
        </div>
        {!isEmpty && (
          <div className="pills-group">
            <div ref={pillsRef} className="drv-pills">
              <Pills selectedFilters={filters} labelMapping={labelMapping} />
            </div>
            {!isEmpty && showSingleToggle && <ClearAllButton onClick={clearAll} theme={theme} />}
          </div>
        )}
      </div>
    </>
  );
};

MobileFilterControls.propTypes = {
  labelMapping: PropTypes.shape({}).isRequired,
  path: PropTypes.string,
  isVans: PropTypes.bool.isRequired,
  controller: PropTypes.string,
};

MobileFilterControls.defaultProps = {
  path: '',
  controller: '',
};

export default MobileFilterControls;
