/* eslint-disable eqeqeq */
import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from '../../store/store';
import { getQueryStringParameters, objectToQueryStringParameters } from '../../utils';

// Create a context
const ElasticSearchContext = createContext(null);

const PRICE_FILTERS = ['monthly_prices', 'term', 'mileage', 'initial_payment', 'funder'];

const PRICES_FILTERS_COUNT = ['monthly_prices', 'funder'];

// Context provider component
export const ElasticSearchProvider = ({ path, labelMapping, children }) => {
  const [allElasticSearchOptions, setAllElasticSearchOptions] = useState({});
  const [availableElasticSearchOptions, setAvailableElasticSearchOptions] = useState({});

  const initialLoadCompleted = useRef(false);

  const defaultSelectedFilters = {
    ...getQueryStringParameters(),
  };

  const { filters: selectedFilters } = useSelector((state) => state.filterControls);
  const dispatch = useDispatch();

  const setSelectedFilter = (event) => {
    const { name, value, checked, type } = event.target;

    const newSelectedFilters = { ...selectedFilters };

    if (checked) {
      if (type === 'radio') {
        newSelectedFilters[name] = value;
      } else if (type === 'checkbox') {
        newSelectedFilters[name] = newSelectedFilters[name] || [];
        newSelectedFilters[name].push(value);
      }
    } else if (type === 'radio') {
      delete newSelectedFilters[name];
    } else if (type === 'checkbox') {
      if (newSelectedFilters[name].length === 1) {
        // Delete the whole entry if there's only one left
        delete newSelectedFilters[name];
      } else {
        // Remove item from array
        const index = newSelectedFilters[name].indexOf(value);
        newSelectedFilters[name].splice(index, 1);
      }

      // Remove models when make is unselected
      if (name === 'make[]' && newSelectedFilters['make_model_range[]']) {
        newSelectedFilters['make_model_range[]'] = newSelectedFilters['make_model_range[]'].filter((item) => {
          return item.indexOf(value) !== 0;
        });
      }
    }

    dispatch({ type: 'SET_FILTERS', payload: newSelectedFilters });
  };

  const setSelectedBodytype = (event) => {
    dispatch({ type: 'SET_BODYTYPE_FILTERS', payload: event.target.value });
  };

  const setSingleCheckboxFilter = (event) => {
    const { name, value, checked } = event.target;
    const newSelectedFilters = { ...selectedFilters };
    if (checked) {
      newSelectedFilters[name] = value;
    } else {
      delete newSelectedFilters[name];
    }
    dispatch({ type: 'SET_FILTERS', payload: newSelectedFilters });
  };

  const removeSelectedFilters = (name) => {
    const newSelectedFilters = { ...selectedFilters };

    if (Array.isArray(name)) {
      name.forEach((nameItem) => delete newSelectedFilters[nameItem]);
    } else {
      delete newSelectedFilters[name];
    }

    dispatch({ type: 'SET_FILTERS', payload: newSelectedFilters });
  };

  const filterParams = objectToQueryStringParameters(selectedFilters);

  const initialiseReduxState = () => {
    dispatch({ type: 'INITIALISE_FILTERS', payload: defaultSelectedFilters });
  };

  const getAvailableCounts = (data) => {
    const counts = {};

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (key.startsWith('prices.')) {
          counts[key.replace('prices.', '')] = data[key].prices[key].doc_count;
        } else {
          counts[key] = data[key].doc_count;
        }
      }
    }

    counts.count = data.count;

    return counts;
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (path && !initialLoadCompleted.current) {
        try {
          const initialResponse = await axios.get(`${path}.json`, {
            params: { response: 'json' },
          });
          setAllElasticSearchOptions(initialResponse.data);
          initialiseReduxState();
          initialLoadCompleted.current = true;
        } catch (error) {
          console.error('Failed to fetch initial ElasticSearch options', error);
        }
      }
    };

    fetchInitialData();
  }, [path]);

  useEffect(() => {
    const fetchFilteredData = async () => {
      if (path) {
        try {
          const filteredResponse = await axios.get(`${path}.json?${filterParams}`, {
            params: { response: 'json' },
          });
          const availableData = filteredResponse.data;

          if (availableData) {
            dispatch({
              type: 'TOTAL_COUNTS',
              payload: getAvailableCounts(availableData),
            });
            setAvailableElasticSearchOptions(availableData);
          }
        } catch (error) {
          console.error('Failed to fetch filtered ElasticSearch options', error);
        }
      }
    };

    fetchFilteredData();
  }, [filterParams]);

  const allFiltersFor = (name) => {
    let priceFilterName;
    let allBuckets;
    let availableBuckets;
    let allOptions;
    let availableOptions;

    if (PRICE_FILTERS.includes(name)) {
      priceFilterName = `prices.${name}`;
      if (!allElasticSearchOptions[priceFilterName] || !availableElasticSearchOptions[priceFilterName]) return [];

      allBuckets = allElasticSearchOptions[priceFilterName].prices[priceFilterName][name].buckets;
      availableBuckets = availableElasticSearchOptions[priceFilterName].prices[priceFilterName][name].buckets;
      allOptions = allBuckets.map((bucket) => bucket.key.toString());
      availableOptions = availableBuckets.map((bucket) => bucket.key.toString());
      return allOptions.map((option) => ({
        value: (labelMapping && labelMapping[option]) || option,
        label: option,
        enabled: availableBuckets.find((o) => o.key == option)
          ? availableBuckets.find((o) => o.key == option).doc_count > 0
          : false,
        count:
          PRICES_FILTERS_COUNT.includes(name) &&
          availableBuckets.find((o) => o.key == option) &&
          availableBuckets.find((o) => o.key == option).deals.doc_count,
      }));
    }
    if (!allElasticSearchOptions[name] || !availableElasticSearchOptions[name]) return [];

    allBuckets = allElasticSearchOptions[name].buckets;
    availableBuckets = availableElasticSearchOptions[name].buckets;
    allOptions = allBuckets.map((bucket) => bucket.key_as_string || bucket.key.toString());
    availableOptions = availableBuckets.map((bucket) => bucket.key_as_string || bucket.key.toString());

    return allOptions.map((option) => {
      const availableBucket = availableBuckets.find((o) => o.key == option || o.key_as_string == option);
      return {
        value: option,
        enabled: availableOptions.indexOf(option) > -1,
        count: (!!availableBucket && availableBucket.doc_count) || 0,
      };
    });
  };

  const hasAnyOption = (name, value) => {
    if (!allElasticSearchOptions[name]) return false;
    // eslint-disable-next-line eqeqeq
    const bucket = allElasticSearchOptions[name].buckets.find((b) => b.key_as_string === value || b.key == value);
    if (!bucket) return false;
    return bucket.doc_count > 0;
  };

  const availableFiltersFor = (name) => {
    if (!availableElasticSearchOptions[name]) return [];

    return availableElasticSearchOptions[name].buckets.map((bucket) => ({
      value: bucket.key_as_string || bucket.key,
      count: bucket.doc_count,
    }));
  };

  // Provide the context with the values
  return (
    <ElasticSearchContext.Provider
      // TODO: fix eslint error instead of disabling it
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        allFiltersFor,
        availableFiltersFor,
        hasAnyOption,
        props: {
          selectedFilters,
          setSelectedFilter,
          setSelectedBodytype,
          removeSelectedFilters,
          setSingleCheckboxFilter,
        },
      }}
    >
      {children}
    </ElasticSearchContext.Provider>
  );
};

// Hook to use the context
export const useElasticSearch = () => {
  const context = useContext(ElasticSearchContext);
  if (!context) {
    throw new Error('useElasticSearch must be used within a ElasticSearchProvider');
  }
  return context;
};

ElasticSearchProvider.propTypes = {
  path: PropTypes.string,
  labelMapping: PropTypes.shape({}),
  children: PropTypes.node,
};

ElasticSearchProvider.defaultProps = {
  path: '',
  labelMapping: {},
  children: null,
};

/* eslint-disable react/jsx-props-no-spreading */
// Wrapper component to include Redux Provider
export default function ElasticSearchWrapper(props) {
  return (
    <Provider store={store}>
      <ElasticSearchProvider {...props} />
    </Provider>
  );
}
/* eslint-enable */
