import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const InStockPills = ({ selectedFilters }) => {
  return !!selectedFilters.stock && <Pill category="stock" label="In Stock" value={selectedFilters.stock} />;
};

InStockPills.propTypes = {
  selectedFilters: PropTypes.shape({
    stock: PropTypes.string,
  }).isRequired,
};

export default InStockPills;
