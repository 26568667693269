import React from 'react';
import { useElasticSearch } from './ElasticSearch';
import FilterDrawerItem from '../ui/FilterDrawerItem';

const FundersDropdown = () => {
  const elasticSearch = useElasticSearch();

  return (
    <div style={{ width: '230px', flexShrink: 0 }}>
      <FilterDrawerItem
        title="Funders"
        allText="Any"
        name="funder"
        options={elasticSearch.allFiltersFor('funder')}
        elasticSearchProps={elasticSearch.props}
        isDesktop
      />
    </div>
  );
};

export default FundersDropdown;
