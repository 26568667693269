import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

function Pill({ category, value, label = value, dismissable, isMake, isLast, gaFilterName }) {
  const dispatch = useDispatch();

  const handleDismiss = (event) => {
    event.persist();

    if (dismissable) {
      dispatch({
        type: 'SET_FILTER',
        payload: { category, value },
      });

      const form = event.target.closest('form');

      setTimeout(() => {
        form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
      }, 100);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classnames('drv-pill', {
        'drv-pill--close': dismissable,
        'drv-pill--make': isMake,
        'drv-pill--last': isLast,
      })}
      data-action="click->ga-filters#push"
      data-ga-filters-event-action="pill_deleted"
      data-ga-filters-filter-name={gaFilterName}
      data-ga-filters-filter-option={value}
      onClick={handleDismiss}
    >
      {label}
    </div>
  );
}

Pill.propTypes = {
  category: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.string,
  dismissable: PropTypes.bool,
  isMake: PropTypes.bool,
  isLast: PropTypes.bool,
  gaFilterName: PropTypes.string,
};

Pill.defaultProps = {
  dismissable: true,
  isMake: false,
  isLast: false,
  gaFilterName: '',
};

export default Pill;
