/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { pushData } from '../../ga/eligibility-checker-ga-data';

const FinalScreen = ({ image, mobileImage, title, description, score }) => {
  return (
    <div className="c-survey-modern">
      <div className="c-survey-modern__background c-survey-modern__background--final">
        <picture className="responsive-background">
          <source media="(min-width: 992px)" srcSet={image} />
          <source media="(max-width: 991px)" srcSet={mobileImage} />
          <img src={image} alt="Background" className="responsive-background__image" />
        </picture>
      </div>
      <div className="c-survey-modern__content c-survey-modern__content--final">
        <div className="c-survey-modern__inner-wrapper">
          <h4
            className={classNames(
              'c-survey-modern__heading c-survey-modern__heading--large',
              score === 1 && 'c-survey-modern__heading--green',
            )}
          >
            {title}
          </h4>
          <p className="c-survey-modern__final-description">{description}</p>
          <div>
            <a
              href="/special-offers"
              className="c-survey-modern__deals-link"
              onClick={() => {
                pushData({
                  step: 6,
                  stepName: 'result',
                  action: 'viewed_lease_deals',
                });
              }}
            >
              View lease deals
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalScreen;

FinalScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};
