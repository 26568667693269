import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getQueryStringParameters } from '../../utils';

const DerivativeOptions = ({ dataGroups }) => {
  const [selected, setSelected] = useState(getQueryStringParameters());
  const [data, setData] = useState({});
  // const [state, setState] = useState({
  //   selected: getQueryStringParameters(),
  //   data: {},
  // });

  const dependentFields = {
    // the value fields will be unselected when you select the key field
    'search[make]': ['search[model_range]', 'search[model]', 'search[trim]'],
  };

  const alwaysEnabledFilters = {
    // you can force filters to never have their options disabled
    // e.g.
    // 'make': true,
  };

  const unselectWhenSelected = (selectedField, state) => {
    const selectedFieldName = selectedField.name;
    const unselectFields = dependentFields[selectedFieldName];

    if (unselectFields) {
      const form = selectedField.closest('form');
      unselectFields.forEach((fieldName) => {
        if (form[fieldName]) form[fieldName].value = '';
        delete state.selected[fieldName];
      });
    }
  };

  const isOptionDisabled = (filterName, option, activeData) => {
    const isAlwaysEnabled = alwaysEnabledFilters[filterName];
    if (typeof isAlwaysEnabled !== 'undefined') return !isAlwaysEnabled;

    const activeOptions = activeData[filterName];
    return activeOptions && activeOptions.length > 0 && activeOptions.indexOf(option) === -1;
  };

  const getDataFromJSON = (json) => {
    const getKeys = (groupName) => {
      if (!json[groupName]) return null;
      return json[groupName].buckets.map(({ key, key_as_string }) => key_as_string || key);
    };

    // convert the array of dataGroups into an object including each group's filter values
    return dataGroups.reduce((object, { key: value }) => ({ ...object, [value]: getKeys(value) }), {});
  };

  const UpdateData = (json) => {
    setData(getDataFromJSON(json));
  };

  const SelectFilter = (event) => {
    const updatedState = { ...{ selected, data } };

    // select this field
    updatedState.selected[event.target.name] = event.target.value;

    // some fields might need to be unselected when this field is selected
    unselectWhenSelected(event.target, updatedState);

    event.target.closest('form').dispatchEvent(new Event('submit', { bubbles: true }));

    setData(updatedState.data);
    setSelected(updatedState.selected);
  };

  const onUpdateDataEvent = (event) => {
    UpdateData(event.detail);
  };

  useEffect(() => {
    // always get the filterable values from the page on load
    UpdateData(window.derivativeJSON);

    // listen out for more filterable values being sent back from Ajax responses after filtering
    document.addEventListener('derivatives#update-data', onUpdateDataEvent);

    // unbind the event listener when this component unmounts
    return () => document.removeEventListener('derivatives#update-data', onUpdateDataEvent);
  }, []);

  return (
    <>
      {dataGroups.map(({ key: dataGroup, name }) => {
        const options = data && data[dataGroup] ? data[dataGroup] : [];
        if (options.length === 0) return null;

        return (
          <div className="form-group select optional" key={dataGroup}>
            <label className="select optional" htmlFor={`search[${dataGroup}]`}>
              {name}
            </label>
            <select
              className="form-control select optional"
              name={`search[${dataGroup}]`}
              id={`search[${dataGroup}]`}
              onChange={SelectFilter}
              value={selected[`search[${dataGroup}]`]}
            >
              <option value="" />
              {options.map((item) => (
                <option disabled={isOptionDisabled(dataGroup, item, data)} key={item} value={item}>
                  {{
                    true: 'Yes',
                    false: 'No',
                  }[item] || item}
                </option>
              ))}
            </select>
          </div>
        );
      })}
    </>
  );
};

DerivativeOptions.propTypes = {
  dataGroups: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default DerivativeOptions;
