import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import AllSpecialOffersFilters from './AllSpecialOffersFilters';
import ShortTermSpecialOffersFilters from './ShortTermSpecialOffersFilters';
import ElectricSpecialOffersFilters from './ElectricSpecialOffersFilters';
import StockOffersFilters from './StockOffersFilters';
import VanSpecialOffersFilters from './VanSpecialOffersFilters';

const SpecialOffersFilters = ({ isDesktop, action, isVans, controller }) => {
  const { isOpen, isFixed } = useSelector((state) => state.filterControls);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_IS_VANS', payload: isVans });
    dispatch({ type: 'SET_CONTROLLER', payload: controller });
  }, [dispatch, isVans, controller]);

  const handleVisible = useCallback(() => {
    const filtersRect = document.querySelector('.drv-filter-desktop').getBoundingClientRect();
    const isAtTop = filtersRect.top <= 48;
    const isWideScreen = window.innerWidth > 1500;

    // Update isFixed only if its state needs to change
    if (isFixed !== isAtTop) {
      dispatch({ type: 'SET_FIXED', payload: isAtTop });
    }

    // Handle isOpen state
    if (isAtTop) {
      // Dispatch SET_OPEN only when transitioning from not fixed to fixed
      if (!isFixed && isOpen) {
        dispatch({ type: 'SET_OPEN', payload: false });
      }
    } else if (isOpen) {
      // Reset isOpen to false when scrolling back up past the sticky point
      // and only if isOpen is currently true
      dispatch({ type: 'SET_OPEN', payload: false });
    }

    // Ensure search bar layering is correct
    const search = document.querySelector('.g-search');
    search.style.zIndex = filtersRect.top <= 49 && isWideScreen ? '1' : '4';
  }, [dispatch, isFixed, isOpen]);

  useEffect(() => {
    if (isDesktop) {
      document.addEventListener('scroll', handleVisible);
      document.addEventListener('resize', handleVisible);
      return () => {
        document.removeEventListener('scroll', handleVisible);
        document.removeEventListener('resize', handleVisible);
      };
    }
  }, [isDesktop, handleVisible, isOpen]);

  useEffect(() => {
    if (isDesktop) {
      const filtersWrapper = document.querySelector('.drv-filter-desktop');
      if (filtersWrapper) {
        if (isOpen) {
          filtersWrapper.classList.add('drv-filter-desktop--open');
        } else {
          filtersWrapper.classList.remove('drv-filter-desktop--open');
        }
      }
    }
  }, [isOpen, isDesktop]);

  return (
    <div className={`${isDesktop ? 'drv-desktop-filter-group' : ''}`}>
      <ul
        className={classNames([
          isDesktop ? 'drv-desktop-filters' : 'drv-mobile-filters',
          !isOpen && isDesktop && isFixed ? 'drv-desktop-filters--condensed' : '',
        ])}
      >
        {(function () {
          switch (action) {
            case 'all':
            case 'used':
              return <AllSpecialOffersFilters isDesktop={isDesktop} />;
            case 'short_term':
            case 'subscription':
              return <ShortTermSpecialOffersFilters isDesktop={isDesktop} />;
            case 'electric':
              return <ElectricSpecialOffersFilters isDesktop={isDesktop} />;
            case 'vans':
              return <VanSpecialOffersFilters isDesktop={isDesktop} />;
            case 'stock':
              return <StockOffersFilters isDesktop={isDesktop} />;
            default:
          }
        })()}
      </ul>
    </div>
  );
};

SpecialOffersFilters.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  isVans: PropTypes.bool,
  controller: PropTypes.string,
};

SpecialOffersFilters.defaultProps = {
  isVans: false,
  controller: '',
};

export default SpecialOffersFilters;
