import React from 'react';
import { useElasticSearch } from './ElasticSearch';
import FilterDrawerItem from '../ui/FilterDrawerItem';

const EstimatedDeliveryDropdown = () => {
  const elasticSearch = useElasticSearch();

  return (
    <div style={{ width: '244px', flexShrink: 0 }}>
      <FilterDrawerItem
        title="Estimated delivery"
        allText="Anytime"
        name="estimated_delivery"
        options={elasticSearch.allFiltersFor('estimated_delivery')}
        elasticSearchProps={elasticSearch.props}
        isDesktop
      />
    </div>
  );
};

export default EstimatedDeliveryDropdown;
