import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const BodyTypePills = ({ selectedFilters }) => {
  return (
    <>
      {selectedFilters['bodytype_categories[]'] &&
        selectedFilters['bodytype_categories[]'].map((value) => (
          <Pill key={value} category="bodytype_categories[]" value={value} gaFilterName="body_type" />
        ))}
      {selectedFilters.bodytype_categories && (
        <Pill category="bodytype_categories" value={selectedFilters.bodytype_categories} />
      )}
    </>
  );
};

BodyTypePills.propTypes = {
  selectedFilters: PropTypes.shape({
    bodytype_categories: PropTypes.string,
    'bodytype_categories[]': PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default BodyTypePills;
