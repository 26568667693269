import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const FunderPills = ({ selectedFilters }) => {
  return !!selectedFilters.funder && <Pill category="funder" value={selectedFilters.funder} />;
};

FunderPills.propTypes = {
  selectedFilters: PropTypes.shape({
    funder: PropTypes.string,
  }).isRequired,
};

export default FunderPills;
