import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const Manufacturer = ({ name, options, isDesktop, elasticSearchProps }) => {
  const removeSelectedFilters = () => {
    // also remove the Model when Manufacturer is set to All
    elasticSearchProps.removeSelectedFilters([name, 'make_model_range[]']);
  };

  return (
    <FilterDrawerItem
      title="Manufacturer"
      name={name}
      options={options}
      elasticSearchProps={{ ...elasticSearchProps, removeSelectedFilters }}
      isDesktop={isDesktop}
    />
  );
};

Manufacturer.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

Manufacturer.defaultProps = {
  options: [],
  isDesktop: false,
};

export default Manufacturer;
