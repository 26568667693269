import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const PayloadPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.payload && (
      <Pill category="payload" label={`${selectedFilters.payload}`} value={selectedFilters.payload} />
    )
  );
};

PayloadPills.propTypes = {
  selectedFilters: PropTypes.shape({
    payload: PropTypes.string,
  }).isRequired,
};

export default PayloadPills;
