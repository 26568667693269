import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const EstimatedDelivery = ({ name, options = [], elasticSearchProps }) => {
  const count = elasticSearchProps.selectedFilters[name] ? elasticSearchProps.selectedFilters[name] : 'Anytime';

  return (
    <FilterDrawerItem
      title="Estimated delivery"
      allText="Anytime"
      count={count}
      name={name}
      options={options}
      elasticSearchProps={elasticSearchProps}
    />
  );
};

EstimatedDelivery.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

EstimatedDelivery.defaultProps = {
  options: [],
};

export default EstimatedDelivery;
