import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const Seats = ({ name, options, isDesktop, elasticSearchProps }) => {
  return (
    <FilterDrawerItem
      title="No of seats"
      name={name}
      options={options}
      elasticSearchProps={elasticSearchProps}
      isDesktop={isDesktop}
    />
  );
};

Seats.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

Seats.defaultProps = {
  options: [],
  isDesktop: false,
};

export default Seats;
