import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import ContactField from '../ContactUs/ContactField';
import OptOut from '../Forms/OptOut';

const FleetSolutionsForm = ({ postURL, channelID }) => {
  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    telephone: '',
    fleetSize: '',
    message: '',
    authenticity_token: '',
    disabled: false,
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    setState({
      ...state,
      disabled: true,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
    });
    formRef.current.submit();
  };

  return (
    <form
      className="c-form-contact-footer bootstrap-form"
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />
      <div className="row">
        <div className="col-12 col-xl-6">
          <ContactField
            labelText="First name *"
            id="first_name"
            type="text"
            onChange={(event) => setState({ ...state, first_name: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.first_name}
            value={state.first_name}
          />
        </div>
        <div className="col-12 col-xl-6">
          <ContactField
            labelText="Last name *"
            id="last_name"
            type="text"
            onChange={(event) => setState({ ...state, last_name: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.last_name}
            value={state.last_name}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-6">
          <ContactField
            labelText="Business name *"
            id="company_name"
            type="text"
            onChange={(event) => setState({ ...state, company_name: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.company_name}
            value={state.company_name}
          />

          <ContactField
            labelText="Email *"
            id="contact_email"
            type="email"
            onChange={(event) => setState({ ...state, email: event.target.value })}
            ref={register({
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            hasError={!!errors.contact_email}
            value={state.email}
          />

          <ContactField
            labelText="Telephone *"
            id="contact_telephone"
            type="tel"
            onChange={(event) => setState({ ...state, telephone: event.target.value })}
            ref={register({
              required: true,
              validate: (value) => isValidNumber(value, 'GB'),
            })}
            hasError={!!errors.contact_telephone}
            value={state.telephone}
          />
        </div>
        <div className="col-12 col-xl-6">
          <ContactField
            labelText="Fleet size *"
            id="contact_fleet_size"
            onChange={(event) => setState({ ...state, fleetSize: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.contact_fleetSize}
            value={state.fleetSize}
            variant="select"
            options={[
              { value: '', text: 'Select fleet size' },
              { value: '1to9', text: '1-9' },
              { value: '10to49', text: '10-49' },
              { value: '50', text: '50+' },
            ]}
          />

          <ContactField
            labelText="Comments"
            rows={5}
            id="contact_message"
            onChange={(event) => setState({ ...state, message: event.target.value })}
            ref={register({ required: false })}
            hasError={!!errors.contact_message}
            value={state.message}
            variant="textarea"
            style={{ lineHeight: 1.3 }}
          />
        </div>
      </div>
      <div className="c-form-contact-footer__form-group">
        <button className="c-form-contact-footer__btn" type="submit" disabled={state.disabled}>
          {state.disabled ? 'Sending...' : 'Send message'}
        </button>
      </div>
      <OptOut on_dark={false} ref={register({ required: false })} />
    </form>
  );
};

FleetSolutionsForm.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
};

export default FleetSolutionsForm;
