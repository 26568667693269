import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const SeatsPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters['vehicle_seating_capacity[]'] &&
    selectedFilters['vehicle_seating_capacity[]'].map((value) => (
      <Pill
        key={value}
        category="vehicle_seating_capacity[]"
        label={`${value} seats`}
        value={value}
        gaFilterName="no_of_seats"
      />
    ))
  );
};

SeatsPills.propTypes = {
  selectedFilters: PropTypes.shape({
    'vehicle_seating_capacity[]': PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default SeatsPills;
