import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const Range = ({ selectedFilters }) => {
  return (
    !!selectedFilters['range[]'] &&
    selectedFilters['range[]'].map((value) => <Pill key={value} category="range[]" value={value} />)
  );
};

Range.propTypes = {
  selectedFilters: PropTypes.shape({
    transmission: PropTypes.string,
  }).isRequired,
};

export default Range;
