import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import BudgetFilters from './BudgetFilters';
import BodyTypeFilters from './BodyTypeFilters';

const CategoryFilters = ({ isDesktop, categoryType, isVans, controller }) => {
  const { isOpen, isFixed } = useSelector((state) => state.filterControls);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_OPEN', payload: true });
    dispatch({ type: 'SET_IS_VANS', payload: isVans });
    dispatch({ type: 'SET_CONTROLLER', payload: controller });
  }, [dispatch, isVans, controller]);

  const handleVisible = useCallback(() => {
    const filtersRect = document.querySelector('.drv-filter-desktop').getBoundingClientRect();
    const isAtTop = filtersRect.top <= 48;
    const isWideScreen = window.innerWidth > 1500;

    // If the state of isFixed needs to change, update it
    if (isFixed !== isAtTop) {
      dispatch({ type: 'SET_FIXED', payload: isAtTop });

      // When isFixed changes to true, or when scrolling up past the sticky point
      if ((isAtTop && !isOpen) || !isAtTop) {
        dispatch({ type: 'SET_OPEN', payload: true });
      }
    }

    // Ensure search bar layering is correct
    const search = document.querySelector('.g-search');
    search.style.zIndex = filtersRect.top <= 49 && isWideScreen ? '1' : '4';
  }, [dispatch, isFixed, isOpen]);

  useEffect(() => {
    if (isDesktop) {
      document.addEventListener('scroll', handleVisible);
      document.addEventListener('resize', handleVisible);
      return () => {
        document.removeEventListener('scroll', handleVisible);
        document.removeEventListener('resize', handleVisible);
      };
    }
  }, [isDesktop, handleVisible]);

  useEffect(() => {
    if (isDesktop) {
      const filtersWrapper = document.querySelector('.drv-filter-desktop');
      if (isOpen) {
        filtersWrapper.classList.add('drv-filter-desktop--open');
        filtersWrapper.classList.remove('drv-filter-desktop--hidden');
      } else {
        filtersWrapper.classList.remove('drv-filter-desktop--open');
        filtersWrapper.classList.add('drv-filter-desktop--hidden');
      }
    }
  }, [isOpen, isDesktop]);

  return (
    <div className={`${isDesktop ? 'drv-desktop-filter-group' : ''}`}>
      <ul
        className={classNames([
          isDesktop ? 'drv-desktop-filters' : 'drv-mobile-filters',
          !isOpen && isDesktop && isFixed ? 'drv-desktop-filters--closed' : '',
          categoryType === 'BodytypeCategory' ? 'drv-desktop-filters--bodytype' : '',
        ])}
      >
        {(function () {
          switch (categoryType) {
            case 'BudgetCategory':
              return <BudgetFilters isDesktop={isDesktop} />;
            case 'BodytypeCategory':
              return <BodyTypeFilters isDesktop={isDesktop} />;
            default:
          }
        })()}
      </ul>
    </div>
  );
};

CategoryFilters.propTypes = {
  isDesktop: PropTypes.bool,
  categoryType: PropTypes.string,
  isVans: PropTypes.bool,
  controller: PropTypes.string,
};

CategoryFilters.defaultProps = {
  isDesktop: false,
  categoryType: '',
  isVans: false,
  controller: '',
};

export default CategoryFilters;
