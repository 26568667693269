import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import FilterList from '../ui/FilterList';
import AnnualMileage from './SpecialOffersFilters/AnnualMileage';
import InitialPayment from './SpecialOffersFilters/InitialPayment';
import Budget from './SpecialOffersFilters/Budget';

import Manufacturer from './SpecialOffersFilters/Manufacturer';
import Model from './SpecialOffersFilters/Model';
import BodyType from './SpecialOffersFilters/BodyType';
import FuelType from './SpecialOffersFilters/FuelType';
import Transmission from './SpecialOffersFilters/Transmission';
import Seats from './SpecialOffersFilters/Seats';
import { useElasticSearch } from './ElasticSearch';

const ShortTermSpecialOffersFilters = ({ isDesktop }) => {
  const elasticSearch = useElasticSearch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_THEME', payload: '' });
  }, []);

  return (
    <>
      <FilterList heading="Vehicle options" isDesktop={isDesktop}>
        <FuelType
          name="display_fueltype[]"
          options={elasticSearch.allFiltersFor('display_fueltype')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <Manufacturer
          name="make[]"
          options={elasticSearch.allFiltersFor('make')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <Model
          name="make_model_range[]"
          options={elasticSearch.availableFiltersFor('make_model_range')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <Budget
          name="monthly_prices"
          options={elasticSearch.allFiltersFor('monthly_prices')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <BodyType
          name="bodytype_categories[]"
          options={elasticSearch.allFiltersFor('bodytype_categories')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <Transmission
          name="transmission"
          options={elasticSearch.allFiltersFor('transmission')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <Seats
          name="vehicle_seating_capacity[]"
          options={elasticSearch.allFiltersFor('vehicle_seating_capacity')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />
      </FilterList>

      <FilterList heading="Contract options" isDesktop={isDesktop}>
        <AnnualMileage
          name="mileage"
          options={elasticSearch.allFiltersFor('mileage')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <InitialPayment
          name="initial_payment"
          options={elasticSearch.allFiltersFor('initial_payment')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />
      </FilterList>
    </>
  );
};

ShortTermSpecialOffersFilters.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};

export default ShortTermSpecialOffersFilters;
