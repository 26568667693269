import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { ScrollSnapSlider } from 'scroll-snap-slider';

import { useElasticSearch } from './ElasticSearch';
import { getQueryStringParameters } from '../../utils';
import ArrowLeftIcon from '../../../assets/images/arrow-left-white.svg';
import ArrowRightIcon from '../../../assets/images/arrow-right-white.svg';

const VanBodytypeFilter = ({ isDesktop }) => {
  const dispatch = useDispatch();
  const sliderRef = useRef();
  const [selectedSlide, setSelectedSlide] = useState();
  const options = [
    { label: 'All vans', value: '', category: '' },
    { label: 'Small', value: 'Small Van', category: 'bodytype_categories[]' },
    { label: 'Medium', value: 'Medium Van', category: 'bodytype_categories[]' },
    { label: 'Large', value: 'Large Van', category: 'bodytype_categories[]' },
    { label: 'Pickup', value: 'Pickup', category: 'bodytype_categories[]' },
    {
      label: 'Conversions',
      value: 'Conversions',
      category: 'bodytype_categories[]',
    },
    { label: 'Electric', value: 'true', category: 'hybrid_or_electric' },
  ];

  const elasticSearch = useElasticSearch();

  // Navigate to selected slide if filter is selected on page load
  useEffect(() => {
    const params = getQueryStringParameters();
    const match =
      params &&
      Object.fromEntries(
        Object.entries(params).filter(
          ([key]) => key.includes('hybrid_or_electric') || key.includes('bodytype_categories[]'),
        ),
      );
    const value =
      Object.keys(match).length !== 0 && options.map((option) => option.value).indexOf(Object.entries(match)[0][1]);
    setSelectedSlide(value);
  }, []);

  const handleSelect = (event) => {
    switch (event.target.name) {
      case '':
        dispatch({ type: 'RESET_FILTERS' });
        break;
      case 'hybrid_or_electric':
        dispatch({ type: 'SET_ELECTRIC' });
        break;
      default:
        elasticSearch.props.setSelectedBodytype(event);
        break;
    }

    const form = event.target.closest('form');

    setTimeout(() => {
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 100);
  };

  useEffect(() => {
    const slider = new ScrollSnapSlider(sliderRef.current);

    const prev = document.querySelector('.drv-filter-van-bodytypes .indicators .arrow.prev');
    const next = document.querySelector('.drv-filter-van-bodytypes .indicators .arrow.next');

    const updateArrows = function () {
      prev.classList.toggle('disabled', sliderRef.current.scrollLeft === 0);
      next.classList.toggle(
        'disabled',
        sliderRef.current.scrollLeft + sliderRef.current.offsetWidth === sliderRef.current.scrollWidth,
      );
    };

    prev.addEventListener('click', () => {
      slider.slideTo(slider.slide - 1);
    });

    next.addEventListener('click', () => {
      slider.slideTo(slider.slide + 1);
    });

    slider.addEventListener('slide-pass', updateArrows);
    slider.addEventListener('slide-stop', updateArrows);

    if (selectedSlide) slider.slideTo(selectedSlide);
  }, [sliderRef, selectedSlide]);

  return (
    <div
      className={classnames('drv-filter-van-bodytypes', {
        'drv-filter-van-bodytypes__mobile': !isDesktop,
        'drv-filter-van-bodytypes__desktop': isDesktop,
      })}
    >
      <div className="drv-filter-van-bodytypes__title">Choose your body type</div>
      <div className="indicators prev">
        <button type="button" className="arrow prev disabled">
          <img src={ArrowLeftIcon} alt="" srcSet="" />
        </button>
      </div>
      <div ref={sliderRef} className="scroll-snap-slider bodytype-slider">
        {options.map(({ value, label, category }, index) => {
          const selected = elasticSearch.props.selectedFilters[category]?.includes(value);
          const allSelected =
            !elasticSearch.props.selectedFilters['bodytype_categories[]'] &&
            !elasticSearch.props.selectedFilters.hybrid_or_electric;
          const isAll = category === '';
          const isElectric = category === 'hybrid_or_electric';

          return (
            <label
              key={value}
              htmlFor={`${category}-${label}`}
              className={classnames(
                `scroll-snap-slide drv-filter-van-bodytypes__button drv-filter-van-bodytypes__button--${
                  label === 'All vans' ? 'all' : label.toLowerCase()
                }`,
                {
                  'drv-filter-van-bodytypes__button--selected': selected || (isAll && allSelected),
                  'drv-filter-van-bodytypes__button--electric': isElectric,
                },
              )}
            >
              <input
                type="checkbox"
                name={category}
                id={`${category}-${label}`}
                data-index={index}
                data-action="click->ga#push"
                data-ga-parent-title="Body Type"
                data-ga-event-action="selectedFilter"
                data-ga-filter-option={label}
                value={value}
                checked={isAll ? allSelected : selected}
                onChange={(event) => handleSelect(event)}
              />
              <div className="icon" />
              {label}
            </label>
          );
        })}
      </div>
      <div className="indicators next">
        <button type="button" className="arrow next">
          <img src={ArrowRightIcon} alt="" srcSet="" />
        </button>
      </div>
    </div>
  );
};

VanBodytypeFilter.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};

export default VanBodytypeFilter;
