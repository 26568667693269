import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import DerivativeFilters from './DerivativeFilters';

const PricingFilters = ({ path }) => {
  const dataGroups = [
    { key: 'channels', name: 'Channels' },
    { key: 'condition', name: 'Condition' },
    { key: 'managed_by', name: 'Managed by' },
    { key: 'make', name: 'Make' },
    { key: 'model_range', name: 'Model range' },
    { key: 'model', name: 'Model' },
    { key: 'trim', name: 'Trim' },
    { key: 'model_year', name: 'Model year' },
    { key: 'transmission', name: 'Transmission' },
    { key: 'display_fueltype', name: 'Fuel type' },
    { key: 'bodytype_categories', name: 'Bodytype' },
    { key: 'winning_funders', name: 'Winning funder' },
    { key: 'funder_special', name: 'Funder special' },
    { key: 'total_care', name: 'Total care' },
    { key: 'published_state', name: 'Visible state of derivative' },
    { key: 'available_deals', name: 'Deal is published and live' },
    { key: 'published_unavailable_deals', name: 'Deal is published but not live' },
    { key: 'draft_deals', name: 'Deal is in draft' },
    { key: 'stock_deals', name: 'Deal is in stock' },
  ];

  return (
    <form
      noValidate="novalidate"
      className="simple_form search"
      action="/backoffice/derivatives/pricing"
      acceptCharset="UTF-8"
      data-remote="true"
      method="get"
    >
      <div className="form-group string optional search_keywords">
        <label className="string optional" htmlFor="search_keywords">
          Cap ID or code
        </label>
        <input
          className="form-control string optional"
          placeholder="e.g 012345"
          type="text"
          name="search[keywords]"
          id="search_keywords"
        />
      </div>

      <div className="form-group string optional">
        <label className="string optional" htmlFor="date_range">
          Date standard deal was last updated
        </label>
        <input
          className="form-control string optional datepicker"
          type="text"
          name="search[date_range]"
          id="date_range"
        />
      </div>

      <div className="form-group hidden search_sort_by">
        <input className="form-control hidden" name="search[sort_by]" type="hidden" id="search_sort_by" />
      </div>

      <DerivativeFilters dataGroups={dataGroups} path={path} />

      <input
        className="btn btn-success mt-3 w-100"
        type="submit"
        name="commit"
        value="Save Search"
        data-disable-with="Save Search"
      />
    </form>
  );
};

PricingFilters.propTypes = {
  path: PropTypes.string.isRequired,
};

export default PricingFilters;
