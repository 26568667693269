import React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import store from '../../store/store';
import Button from '../ui/Button';

const MobileFilterActions = () => {
  const dispatch = useDispatch();
  const { totalCounts, theme, isVans, controller } = useSelector((state) => state.filterControls);

  const clearAll = (event) => {
    const form = event.target.closest('form');

    dispatch({ type: 'RESET_FILTERS' });

    setTimeout(() => {
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 1);
  };

  const submitForm = (event) => {
    event.preventDefault();
    const form = document.querySelector(`#${controller}-filters`);

    setTimeout(() => {
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 1);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <span
        className={classnames('drv-link', 'drv-mb-4', {
          'drv-link--gold': !theme,
          [`drv-link--${theme}`]: theme,
        })}
        data-action="ga#push"
        data-ga-filter-name="Clear all"
        onClick={clearAll}
      >
        Clear all
        <svg
          fill={theme === 'electric' ? '#00a3e0' : '#ac8c30'}
          height="20"
          viewBox="228.61 210.78 10.21 12.02"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m233.71817501636602,222.80117904990522 c-2.8198594488203526,0 -5.10526180267334,-2.39004047670465 -5.10526180267334,-5.343179495041285 s2.2854023538529873,-5.343179495041285 5.10526180267334,-5.343179495041285 l0,-1.3352021604064999 l2.9201778432428833,1.8040021347066384 l-2.9201778432428833,1.6168557087302022 l0,-1.2514356213533258 c-2.377137526869774,0 -4.311553131788969,2.022983649566763 -4.311553131788969,4.50895943336427 c0,2.4859757837975067 1.9344156049191952,4.50895943336427 4.311553131788969,4.50895943336427 s4.311553131788969,-2.0062992483332227 4.311553131788969,-4.50895943336427 l0.7937086708843714,0 c0,2.9614812189534057 -2.2854023538529873,5.343179495041285 -5.10526180267334,5.343179495041285 z" />
        </svg>
      </span>
      <Button
        variant={theme || 'primary'}
        data-action="click->ga-filters#push"
        data-ga-filters-event-action="applied_filters_mobile"
        data-drawer-action="close"
        onClick={(e) => submitForm(e)}
      >
        View {totalCounts.count} {isVans ? 'vans' : 'cars'}
      </Button>
    </>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
export default function MobileFilterActionsWrapper(props) {
  return (
    <Provider store={store}>
      <MobileFilterActions {...props} />
    </Provider>
  );
}
/* eslint-enable */
