import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const Transmission = ({ name, options, isDesktop, elasticSearchProps }) => {
  return (
    <FilterDrawerItem
      title="Gearbox"
      name={name}
      options={options}
      elasticSearchProps={elasticSearchProps}
      isDesktop={isDesktop}
    />
  );
};

Transmission.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

Transmission.defaultProps = {
  options: [],
  isDesktop: false,
};

export default Transmission;
