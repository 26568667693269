import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const ElectricPills = ({ selectedFilters }) => {
  return (
    !!selectedFilters.hybrid_or_electric && (
      <Pill category="hybrid_or_electric" label="Electric" value={selectedFilters.hybrid_or_electric} />
    )
  );
};

ElectricPills.propTypes = {
  selectedFilters: PropTypes.shape({
    hybrid_or_electric: PropTypes.string,
  }).isRequired,
};

export default ElectricPills;
