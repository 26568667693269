import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryStringParameters } from '../../utils';

const SortByDropdown = ({ controller, action }) => {
  let options = [];

  if (controller === 'specials') {
    if (action === 'short_term') {
      options = [
        { name: 'Price lowest first', value: '', default: true },
        { name: 'Price highest first', value: 'price|desc' },
        { name: 'Recently added', value: 'created_at|asc' },
        { name: 'Recommended', value: 'position|asc' },
      ];
    } else {
      options = [
        { name: 'Price lowest first', value: 'price|asc' },
        { name: 'Price highest first', value: 'price|desc' },
        { name: 'Recently added', value: 'created_at|asc' },
        { name: 'Recommended', value: '', default: true },
      ];
    }
  } else {
    options = [
      { name: 'Price lowest first', value: ``, default: true },
      { name: 'Price highest first', value: `price|desc` },
      { name: 'Newest', value: 'created_at|asc' },
      { name: 'Oldest', value: 'created_at|desc' },
    ];
  }

  const { sort_by } = useSelector((state) => state.filterControls);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();

  const handleOutsideClicks = (event) => {
    if (open && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SET_SORT_BY',
      payload: getQueryStringParameters().sort_by || options.filter((option) => option.default)[0].value,
    });
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClicks);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClicks);
    };
  }, [open]);

  const handleClick = (event) => {
    event.persist();
    dispatch({
      type: 'SET_SORT_BY',
      payload: options.find((field) => field.value === event.target.value).value,
    });
    setOpen(false);
    setTimeout(() => {
      event.target.closest('form').dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 1);
  };

  return (
    <div className={`drv-filter-controls__sort ${open ? 'drv-filter-controls__sort--open' : ''}`} ref={dropdownRef}>
      <button type="button" className="drv-button drv-button--blue" onClick={() => setOpen(!open)}>
        Sort by
      </button>
      <div className="drv-filter-controls__sort-options">
        {options &&
          options.map((field) => {
            return (
              <li key={field.value} className="drv-filter-option">
                <input
                  type="radio"
                  id={field.name}
                  name="sort_by"
                  className="drv-checkbox drv-checkbox--radio"
                  data-action="ga-filters#push"
                  data-ga-filters-event-action="sort_applied"
                  data-ga-filters-sort-applied={field.name.toLowerCase().replaceAll(' ', '_')}
                  value={field.value}
                  checked={sort_by === field.value}
                  onChange={(event) => {
                    handleClick(event);
                  }}
                />

                <label htmlFor={field.name} className="drv-filter-option__label drv-ml-1">
                  {field.name}
                </label>
              </li>
            );
          })}
      </div>
    </div>
  );
};

SortByDropdown.propTypes = {
  controller: PropTypes.string,
  action: PropTypes.string,
};

SortByDropdown.defaultProps = {
  controller: '',
  action: '',
};

export default SortByDropdown;
