import React from 'react';
import PropTypes from 'prop-types';

import Pill from './Pill';

const FuelTypePills = ({ selectedFilters }) => {
  return (
    <>
      {selectedFilters['display_fueltype[]'] &&
        selectedFilters['display_fueltype[]'].map((value) => (
          <Pill key={value} category="display_fueltype[]" value={value} gaFilterName="fuel_type" />
        ))}
      {selectedFilters.display_fueltype && (
        <Pill category="display_fueltype" value={selectedFilters.display_fueltype} />
      )}
    </>
  );
};

FuelTypePills.propTypes = {
  selectedFilters: PropTypes.shape({
    'display_fueltype[]': PropTypes.arrayOf(PropTypes.string),
    display_fueltype: PropTypes.string,
  }).isRequired,
};

export default FuelTypePills;
