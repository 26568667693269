import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pill from './SpecialOffersFilters/filterPills/Pill';
import { useModelFilters } from './ModelFiltersContext';

const ModelPills = () => {
  const {
    props: { selectedFilters },
  } = useModelFilters();
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(true);
  const { totalCounts } = useSelector((state) => state.filterControls);

  const clearAll = (event) => {
    const form = event.target.closest('form');

    dispatch({ type: 'RESET_FILTERS' });

    setTimeout(() => {
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 1);
  };

  useEffect(() => {
    const filtersWithoutDefaults = { ...selectedFilters };
    delete filtersWithoutDefaults.customer_type;
    delete filtersWithoutDefaults.sort_by;

    setIsEmpty(Object.keys(filtersWithoutDefaults).length === 0);
  }, [selectedFilters]);

  const clearAllButton = (options) => {
    const className = options?.mobile ? 'd-lg-none' : 'd-none d-lg-block';

    return (
      !isEmpty && (
        <button
          className={`${className} drv-filter-controls__clear drv-link drv-link--gold`}
          data-action="click->ga-filters#push"
          data-ga-filters-event-action="cleared_all_filters"
          onClick={clearAll}
        >
          Clear all
        </button>
      )
    );
  };

  return (
    <>
      <div className="drv-filter-controls__mobile--primary mb-3 mt-2 align-items-end">
        <div className="d-lg-none">
          <span className="drv-filter-controls__result-count">{totalCounts.count}</span> results
        </div>
        {clearAllButton({ mobile: true })}
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div data-ga-filters className="drv-pills drv-pills-slider">
          {selectedFilters.trim && <Pill category="trim" value={selectedFilters.trim} />}
          {selectedFilters.display_fueltype && (
            <Pill category="display_fueltype" value={selectedFilters.display_fueltype} gaFilterName="fuel_type" />
          )}
          {selectedFilters.transmission && (
            <Pill category="transmission" value={selectedFilters.transmission} gaFilterName="gearbox" />
          )}
          {selectedFilters.initial_payment && (
            <Pill
              category="initial_payment"
              label={`${selectedFilters.initial_payment} Month initial payment`}
              value={selectedFilters.initial_payment}
              gaFilterName="initial_payment"
            />
          )}
          {selectedFilters.mileage && (
            <Pill
              category="mileage"
              label={`${Math.floor(selectedFilters.mileage).toLocaleString('en-GB')} miles`}
              value={selectedFilters.mileage}
              gaFilterName="annual_mileage"
            />
          )}
          {selectedFilters.term && (
            <Pill
              category="term"
              label={`${selectedFilters.term} month term`}
              value={selectedFilters.term}
              gaFilterName="contract_length"
            />
          )}
        </div>
        {clearAllButton()}
      </div>
    </>
  );
};

export default ModelPills;
