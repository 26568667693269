/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { goToNextStep, goToPreviousStep, setEligibilityAnswer } from '../../store/reducers/eligibility-checker';
import { areAllQuestionsAnswered, calculateCurrentScore } from '../../store/selectors';
import { finalScreenData, steps } from './steps';
import { pushData } from '../../ga/eligibility-checker-ga-data';
import store from '../../store/store';
import Step from './Step';
import FinalScreen from './FinalScreen';

const preloadImages = () => {
  steps.forEach((step) => {
    const img = new Image();
    img.src = step.image;
    const mobileImg = new Image();
    mobileImg.src = step.mobileImage;
  });
  Object.values(finalScreenData).forEach((screen) => {
    const img = new Image();
    img.src = screen.image;
    const mobileImg = new Image();
    mobileImg.src = screen.mobileImage;
  });
};

const EligibilityChecker = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.eligibilityChecker.currentStep);
  const allAnswered = useSelector((state) => areAllQuestionsAnswered(state.eligibilityChecker));
  const currentScore = useSelector((state) => calculateCurrentScore(state.eligibilityChecker, steps));
  const stepsData = useSelector((state) => ({
    about_you: state.eligibilityChecker.about_you,
    address_history: state.eligibilityChecker.address_history,
    employment_status: state.eligibilityChecker.employment_status,
    employment_history: state.eligibilityChecker.employment_history,
    affordability: state.eligibilityChecker.affordability,
    credit_history: state.eligibilityChecker.credit_history,
  }));
  const [finalScreen, setFinalScreen] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const componentRef = useRef(null);

  useEffect(() => {
    preloadImages();
  }, []);

  useEffect(() => {
    const topBarHeight = 130;
    const componentTop = componentRef.current.getBoundingClientRect().top;
    if (componentTop < topBarHeight || componentTop > window.innerHeight) {
      window.scrollTo({
        top: componentTop + window.scrollY - topBarHeight,
        behavior: 'smooth',
      });
    }
  }, [currentStep, finalScreen]);

  const handleNextStep = () => {
    const stepName = steps[currentStep].name;
    const currentValue = stepsData[stepName];

    if (currentStep === 0 && currentValue !== 'over_18') {
      setErrorMessage('You must be over 18 to proceed.');
      return;
    }

    if (currentValue === '') {
      setErrorMessage('Please select an answer');
      return;
    }

    setErrorMessage('');
    pushData({
      step: currentStep,
      stepName,
      action: 'next step',
      currentValue,
    });

    if (allAnswered) {
      setFinalScreen(currentScore);
    } else {
      dispatch(goToNextStep());
    }
  };

  const handlePreviousStep = () => {
    setErrorMessage(''); // Reset error message when going back
    const stepName = steps[currentStep].name;
    pushData({
      step: currentStep,
      stepName,
      action: 'previous step',
      currentValue: stepsData[stepName],
    });

    dispatch(goToPreviousStep());
  };

  const handleSetAnswer = (step, value, score) => {
    setErrorMessage(''); // Reset error message when a value is selected
    dispatch(setEligibilityAnswer(step, value, score));
  };

  const stepComponentData = steps[currentStep];

  return (
    <div ref={componentRef}>
      {finalScreen ? (
        <FinalScreen
          title={finalScreenData[finalScreen].title}
          image={finalScreenData[finalScreen].image}
          mobileImage={finalScreenData[finalScreen].mobileImage}
          description={finalScreenData[finalScreen].description}
          score={finalScreen}
        />
      ) : (
        <Step
          stepData={stepComponentData}
          stepNumber={currentStep}
          currentValue={stepsData[stepComponentData.name]}
          onAnswer={handleSetAnswer}
          onNext={handleNextStep}
          onPrevious={handlePreviousStep}
          steps={steps}
          currentStep={currentStep}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default function EligibilityCheckerProvider(props) {
  return (
    <Provider store={store}>
      <EligibilityChecker {...props} />
    </Provider>
  );
}
