import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import FilterList from '../ui/FilterList';

import Budget from './SpecialOffersFilters/Budget';
import Manufacturer from './SpecialOffersFilters/Manufacturer';
import Model from './SpecialOffersFilters/Model';
import Payload from './SpecialOffersFilters/Payload';
import LoadLength from './SpecialOffersFilters/LoadLength';
import FuelType from './SpecialOffersFilters/FuelType';
import Transmission from './SpecialOffersFilters/Transmission';
import ContractLength from './SpecialOffersFilters/ContractLength';
import InitialPayment from './SpecialOffersFilters/InitialPayment';
import AnnualMileage from './SpecialOffersFilters/AnnualMileage';
import { useElasticSearch } from './ElasticSearch';

const VanSpecialOffersFilters = ({ isDesktop }) => {
  const elasticSearch = useElasticSearch();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_THEME', payload: '' });
  }, []);

  return (
    <>
      <FilterList heading="Vehicle options" isDesktop={isDesktop}>
        <Manufacturer
          name="make[]"
          options={elasticSearch.allFiltersFor('make')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <Model
          name="make_model_range[]"
          options={elasticSearch.availableFiltersFor('make_model_range')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <FuelType
          name="display_fueltype[]"
          options={elasticSearch.allFiltersFor('display_fueltype')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <Transmission
          name="transmission"
          options={elasticSearch.allFiltersFor('transmission')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <Payload
          name="payload"
          options={elasticSearch.allFiltersFor('payload')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <LoadLength
          name="load_length"
          options={elasticSearch.allFiltersFor('load_length')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />
      </FilterList>

      <FilterList heading="Leasing options" isDesktop={isDesktop}>
        <Budget
          name="monthly_prices"
          options={elasticSearch.allFiltersFor('monthly_prices')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <ContractLength
          name="term"
          options={elasticSearch.allFiltersFor('term')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <InitialPayment
          name="initial_payment"
          options={elasticSearch.allFiltersFor('initial_payment')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />

        <AnnualMileage
          name="mileage"
          options={elasticSearch.allFiltersFor('mileage')}
          elasticSearchProps={elasticSearch.props}
          isDesktop={isDesktop}
        />
      </FilterList>
    </>
  );
};

VanSpecialOffersFilters.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};

export default VanSpecialOffersFilters;
