import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';

import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const Budget = ({ name, options, isDesktop, elasticSearchProps }) => {
  const selected =
    elasticSearchProps.selectedFilters[name] &&
    options.find((option) => option.value === elasticSearchProps.selectedFilters[name]);
  const count = selected ? selected.label : '';

  return (
    <FilterDrawerItem
      title="Budget"
      name={name}
      allText="Any"
      options={options}
      count={count}
      elasticSearchProps={elasticSearchProps}
      isDesktop={isDesktop}
    />
  );
};

Budget.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

Budget.defaultProps = {
  options: [],
  isDesktop: false,
};

export default Budget;
