import React from 'react';
import PropTypes from 'prop-types';

import ElectricPills from './ElectricPills';
import MakePills from './MakePills';
import FuelTypePills from './FuelTypePills';
import BudgetPills from './BudgetPills';
import BodyTypePills from './BodyTypePills';
import TransmissionPills from './TransmissionPills';
import SeatsPills from './SeatsPills';
import TermPills from './TermPills';
import MileagePills from './MileagePills';
import InitialPaymentPills from './InitialPaymentPills';
import EstimatedDeliveryPills from './EstimatedDeliveryPills';
import InStockPills from './InStockPills';
import OvoPills from './OvoPills';
import FunderPills from './FunderPills';
import RangePills from './RangePills';
import PayloadPills from './PayloadPills';
import LoadLengthPills from './LoadLengthPills';

const Pills = ({ selectedFilters, labelMapping }) => {
  return (
    <>
      <EstimatedDeliveryPills selectedFilters={selectedFilters} />
      <InStockPills selectedFilters={selectedFilters} />
      <OvoPills selectedFilters={selectedFilters} />
      <InitialPaymentPills selectedFilters={selectedFilters} />
      <ElectricPills selectedFilters={selectedFilters} />
      <FuelTypePills selectedFilters={selectedFilters} />
      <MakePills selectedFilters={selectedFilters} />
      <RangePills selectedFilters={selectedFilters} />
      <PayloadPills selectedFilters={selectedFilters} />
      <LoadLengthPills selectedFilters={selectedFilters} />
      <BudgetPills selectedFilters={selectedFilters} labelMapping={labelMapping} />
      <BodyTypePills selectedFilters={selectedFilters} />
      <TransmissionPills selectedFilters={selectedFilters} />
      <SeatsPills selectedFilters={selectedFilters} />
      <TermPills selectedFilters={selectedFilters} />
      <MileagePills selectedFilters={selectedFilters} />
      <FunderPills selectedFilters={selectedFilters} />
    </>
  );
};

Pills.propTypes = {
  selectedFilters: PropTypes.shape({}).isRequired,
  labelMapping: PropTypes.shape({}).isRequired,
};

export default Pills;
