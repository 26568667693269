import React, { useMemo } from 'react';
import FilterDrawerItem from '../ui/FilterDrawerItem';
import AnnualMileage from './SpecialOffersFilters/AnnualMileage';
import InitialPayment from './SpecialOffersFilters/InitialPayment';
import ContractLength from './SpecialOffersFilters/ContractLength';
import { useModelFilters } from './ModelFiltersContext';

const MobileModelFilters = () => {
  const modelFilters = useModelFilters();

  const withoutButton = useMemo(() => true, []);

  return (
    <div className="drv-ui">
      <hr className="drv-hr" />
      <h4 className="d-flex justify-content-center mt-4 drv-text-subheading drv-text-semi-bold">
        Choose your vehicle options
      </h4>
      <ul className="drv-model-filters drv-model-filters--mobile">
        <FilterDrawerItem
          title="Trim level"
          name="trim"
          options={modelFilters.allFiltersFor('trim')}
          elasticSearchProps={modelFilters.props}
          isDesktop
          withoutButton={withoutButton}
        />
        <FilterDrawerItem
          title="Fuel type"
          name="display_fueltype"
          options={modelFilters.allFiltersFor('display_fueltype')}
          elasticSearchProps={modelFilters.props}
          isDesktop
          withoutButton={withoutButton}
        />
        <FilterDrawerItem
          title="Gearbox"
          name="transmission"
          options={modelFilters.allFiltersFor('transmission')}
          elasticSearchProps={modelFilters.props}
          isDesktop
          withoutButton={withoutButton}
        />
      </ul>
      <hr />
      <h4 className="d-flex justify-content-center drv-text-subheading drv-text-semi-bold">
        Choose your contract options
      </h4>
      <ul className="drv-model-filters drv-model-filters--mobile">
        <InitialPayment
          name="initial_payment"
          options={modelFilters.allFiltersFor('initial_payment')}
          elasticSearchProps={modelFilters.props}
          isDesktop
          withoutButton={withoutButton}
        />
        <AnnualMileage
          name="mileage"
          options={modelFilters.allFiltersFor('mileage')}
          elasticSearchProps={modelFilters.props}
          isDesktop
          withoutButton={withoutButton}
        />
        <ContractLength
          name="term"
          options={modelFilters.allFiltersFor('term')}
          elasticSearchProps={modelFilters.props}
          isDesktop
          withoutButton={withoutButton}
        />
      </ul>
    </div>
  );
};

export default MobileModelFilters;
