import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { getQueryStringParameters } from '../../utils';
import store from '../../store/store';

// Component Imports
import SortOption from '../ui/SortOption';

const SortByFilters = ({ controller, action }) => {
  let options = [];

  if (controller === 'specials') {
    if (action === 'short_term') {
      options = [
        { name: 'Price lowest first', value: '', default: true },
        { name: 'Price highest first', value: 'price|desc' },
        { name: 'Recently added', value: 'created_at|asc' },
        { name: 'Recommended', value: 'position|asc' },
      ];
    } else {
      options = [
        { name: 'Price lowest first', value: 'price|asc' },
        { name: 'Price highest first', value: 'price|desc' },
        { name: 'Recently added', value: 'created_at|asc' },
        { name: 'Recommended', value: '', default: true },
      ];
    }
  } else {
    options = [
      { name: 'Price lowest first', value: ``, default: true },
      { name: 'Price highest first', value: `price|desc` },
      { name: 'Newest', value: 'created_at|asc' },
      { name: 'Oldest', value: 'created_at|desc' },
    ];
  }

  const { sort_by } = useSelector((state) => state.filterControls);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'SET_SORT_BY',
      payload: getQueryStringParameters().sort_by || options.filter((option) => option.default)[0].value,
    });
  }, []);

  const handleClick = (event) => {
    event.persist();
    dispatch({
      type: 'SET_SORT_BY',
      payload: options.find((field) => field.value === event.target.value).value,
    });
    setTimeout(() => {
      event.target.closest('form').dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 1);

    // close sort drawer
    event.target.closest('.g-drawer').classList.remove('drawer-open');
    document.querySelector('body').classList.remove('has-drawer');
    document.querySelector('.drawer-overlay').style.display = 'none';
  };

  return (
    <div className="drv-sort">
      {options &&
        options.map((field) => {
          return (
            <SortOption
              key={field.value}
              type="radio"
              value={field.value}
              name="sort_by"
              label={field.name}
              enabled
              selected={sort_by === field.value}
              onSelect={(event) => {
                handleClick(event);
              }}
              id={`${field.name}-mobile`}
            />
          );
        })}
    </div>
  );
};

SortByFilters.propTypes = {
  controller: PropTypes.string,
  action: PropTypes.string,
};

SortByFilters.defaultProps = {
  controller: '',
  action: '',
};

/* eslint-disable react/jsx-props-no-spreading */
export default function SortByFiltersWrapper(props) {
  return (
    <Provider store={store}>
      <SortByFilters {...props} />
    </Provider>
  );
}
/* eslint-enable */
