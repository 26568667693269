import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';

import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const BodyType = ({ name, options, sort, isDesktop, elasticSearchProps }) => {
  const optionsOrder = ['Pickup', 'Small Van', 'Medium Van', 'Large Van', 'Luton', 'Dropside', 'Tipper'].reverse();

  // .reverse() -> we want all of the options not in this list to be last, but the default with the below sort is that they're first.
  // so we reverse both lists so that the missing options are last instead.

  const sortedOptions =
    sort === 'custom'
      ? [...options]
          .sort((a, b) => {
            return optionsOrder.indexOf(a.value) - optionsOrder.indexOf(b.value);
          })
          .reverse()
      : options;

  return (
    <FilterDrawerItem
      title="Body type"
      name={name}
      options={sortedOptions}
      elasticSearchProps={elasticSearchProps}
      isDesktop={isDesktop}
    />
  );
};

BodyType.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  sort: PropTypes.string,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

BodyType.defaultProps = {
  options: [],
  sort: '',
  isDesktop: false,
};

export default BodyType;
