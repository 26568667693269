// Asset Imports
import icon1 from '../../../assets/images/ec-icon-1.svg';
import icon2 from '../../../assets/images/ec-icon-2.svg';
import icon3 from '../../../assets/images/ec-icon-3.svg';
import icon4 from '../../../assets/images/ec-icon-4.svg';
import icon5 from '../../../assets/images/ec-icon-5.svg';
import icon6 from '../../../assets/images/ec-icon-6.svg';

export const steps = [
  {
    name: 'about_you',
    title: 'About you',
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/mztobcwtkqgyuwizfhhz',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/mztobcwtkqgyuwizfhhz',
    icon: icon1,
    columns: 2,
    question: 'Are you over 18?',
    description: 'You must be 18 years old or older to apply for finance for a lease vehicle.',
    options: [
      {
        label: 'Over 18 years old',
        value: 'over_18',
        description: 'I confirm I am 18 years old or older.',
      },
      {
        label: 'Under 18 years old',
        value: 'under_18',
        description: 'I am not yet 18 years old.',
      },
    ],
  },
  {
    name: 'address_history',
    title: 'Address History',
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/ljuo2o6yuxvsahpxnuwo',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/qra9mfl1fjuzezijmghv',
    icon: icon2,
    columns: 2,
    question: 'How many years address history can you provide?',
    description: 'A minimum of 3 years address history is required to assess your application.',
    options: [
      {
        label: '0 to 2 years',
        value: '0_to_2',
        description: 'Address history available',
        score: 3,
      },
      {
        label: '2 to 3 years',
        value: '2_to_3',
        description: 'Address history available',
        score: 3,
      },
      {
        label: '3 to 5 years',
        value: '3_to_5',
        description: 'Address history available',
        score: 1,
      },
      {
        label: 'Over 5 years',
        value: 'over_5',
        description: 'Address history available',
        score: 1,
      },
    ],
  },
  {
    name: 'employment_status',
    title: 'Employment status',
    columns: 3,
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/xq3zjs5belrtyg3zyda6',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/xq3zjs5belrtyg3zyda6',
    icon: icon3,
    question: 'What best describes your employment status?',
    options: [
      {
        label: 'Full time',
        value: 'full_time',
        description: '',
        score: 1,
      },
      {
        label: 'Part time',
        value: 'part_time',
        description: '',
        score: 1,
      },
      {
        label: 'Contractor',
        value: 'contractor',
        description: '',
        score: 1,
      },
      {
        label: 'Self employed',
        value: 'self_employed',
        description: '',
        score: 1,
      },
      {
        label: 'Unemployed',
        value: 'unemployed',
        description: '',
        score: 2,
      },
      {
        label: 'Home maker',
        value: 'home_maker',
        description: '',
        score: 2,
      },
      {
        label: 'Retired',
        value: 'retired',
        description: '',
        score: 2,
      },
    ],
  },
  {
    name: 'employment_history',
    title: 'Employment history',
    columns: 1,
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/kl6c4byj5hjqc95yh4a2',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/dpeaguo0rioued6igaxj',
    icon: icon4,
    question: 'How many years of employment / regular income history can you provide?',
    options: [
      {
        label: '0 to 3 years',
        value: '0_to_3',
        description: 'I can provide up to 3 years of employment / income history.',
        score: 2,
      },
      {
        label: '3 to 5 years',
        value: '3_to_5',
        description: 'I can provide between 3 and 5 years of employment / income history.',
        score: 1,
      },
      {
        label: 'Over 5 years',
        value: 'over_5',
        description: 'I can provide over 5 years of employment / income history.',
        score: 1,
      },
    ],
  },
  {
    name: 'affordability',
    title: 'Affordability',
    columns: 1,
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/m5ibecrzlm5mh3pdz6e3',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/m5ibecrzlm5mh3pdz6e3',
    icon: icon5,
    question: 'Do you have regular income to afford the monthly lease payments?',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        description: 'I have regular income and can afford monthly lease payments.',
        score: 1,
      },
      {
        label: 'No',
        value: 'no',
        description: 'I do not currently have regular income.',
        score: 3,
      },
    ],
  },
  {
    name: 'credit_history',
    title: 'Credit history',
    columns: 1,
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/afiicxzrshidv23ziiyi',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/afiicxzrshidv23ziiyi',
    icon: icon6,
    question: 'Do you have adverse credit on your credit report?',
    description: 'For example any missed payments, CCJs, IVA scheme, bankruptcy.',
    options: [
      {
        label: 'Yes',
        value: 'yes',
        description: 'I do have adverse credit on my credit report.',
        score: 3,
      },
      {
        label: 'No',
        value: 'no',
        description: 'No I do not have any adverse credit on my credit report.',
        score: 1,
      },
      {
        label: 'Not sure',
        value: 'not_sure',
        description: 'I am not sure whether I have have any adverse credit on my credit report.',
        score: 2,
      },
    ],
  },
];

export const finalScreenData = {
  1: {
    title: 'Great news!',
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/exbdp4mpsrcvtx3kzjfo',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/rkm4okms8lc0x4n7i2xu',
    description: `Your answers indicate you'll be eligible for finance with our panel of lenders. Let's look at the best deal for you now.`,
  },
  2: {
    title: 'Thanks for your answers!',
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/ttrpmgoszaulb0icigiz',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/giyvchujk1szh1ffyrib',
    description: `There's a good chance we'll be able to find a deal to suit you.  Contact us now to look at the best offers available for you now.`,
  },
  3: {
    title: 'Thanks for your answers!',
    image:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/v4dn14iaajdnfbzaklf1',
    mobileImage:
      'https://res.cloudinary.com/ho5waxsnl/image/upload/f_auto,q_auto/v1/eligibility-checker/ybur61axjtr55vg8mmta',
    description: 'We have some lenders who may consider your application. Please contact us to discuss further.',
  },
};
