import React from 'react';
import PropTypes from 'prop-types';

import FilterDrawerItem from '../../ui/FilterDrawerItem';
import { elasticSearchFilterOptionPropTypes, elasticSearchPropsPropTypes } from '../../../propTypes/elasticSearch';

const Model = ({ name, options, isDesktop, elasticSearchProps }) => {
  // e.g. "Audi, A3" is the Make and Model separated by ', '
  const modelNameValueSeparator = ', ';

  options = options.map((option) => {
    // we reverse these so that "label" always exists even if the split fails
    // e.g. an option value of "Audi A3" here would give us label = "Audi A3" and prelabel = undefined
    // while an option value of "Audi, A3" here would give us label = "A3" and prelabel = "Audi"

    // the use of 2 in the split ensures we always get a maximum of 2 parts
    const [label, prelabel] = option.value.split(modelNameValueSeparator, 2).reverse();

    return { ...option, prelabel, label };
  });

  const count = elasticSearchProps.selectedFilters[name]
    ? elasticSearchProps.selectedFilters[name].map((value) => value.replace(modelNameValueSeparator, ' ')).join(', ')
    : undefined;
  const disabled = !elasticSearchProps.selectedFilters['make[]'];

  return (
    <FilterDrawerItem
      title="Model"
      name={name}
      count={count}
      options={options}
      disabled={disabled}
      elasticSearchProps={elasticSearchProps}
      isDesktop={isDesktop}
    />
  );
};

Model.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(elasticSearchFilterOptionPropTypes),
  isDesktop: PropTypes.bool,
  elasticSearchProps: elasticSearchPropsPropTypes.isRequired,
};

Model.defaultProps = {
  options: [],
  isDesktop: false,
};

export default Model;
